<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				hero:{},
				input:{country:""},
				mrCountry:[]
            }
		},
		computed:{
			mrValidation(){
				return this.$root.config.mrValidation.affiliate
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.apiGetStaticHero()
			this.apiGetCountry();
			
			this.$set(this.$root.config.mrValidation, 'affiliate', {
				outlet_name:"required|minlength:3",
				hp_no:"required|minlength:10|maxlength:16",
				url:"",
				country:"required",
				city:"required",
				zip_code:"required|maxlength:10|minlength:3",
				address:"required|minlength:3",
			})
		},
        methods: {
        	async getStaticHero(id){
	            return (await Gen.apirest('/static-hero', {id:id}, ()=>{})).data
	        },
			async apiGetCountry(){
	            return this.mrCountry = (await Gen.apirest('/country-all', {}, ()=>{})).data
	        },
	        async apiGetStaticHero(){
	            this.hero = await this.getStaticHero(7)
	            setTimeout(()=>{ SEMICOLON.documentOnLoad.init() },500)
	        },
			onSubmit(e) {
				if(e&&e.btnLoading()) return;
				Gen.apirest("/submit-reg-affiliate",this.input,(err,resp)=>{
					if(e) e.btnUnloading()
					if(err) return swal(err.resp.message,"","warning")
					this.input = {}
					swal(resp.message,"","success")
						.then((result)=>{
							if(result){
								location.reload()
							}
						})
				},"POST")
			},
        },
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element boxed-slider notoppadding">
		    <div class="clearfix">
		        <div class="fslider" data-touch="false" data-easing="easeInQuad">
		            <div class="flexslider">
		                <div class="slider-wrap">
							<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.img_desktop)">
								<img :src="uploader(hero.img_desktop)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero.title }}</h2>
									<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.sub_title">{{hero.sub_title}}</p>
								</div>
							</div>
							<div v-else class="slide" :data-thumb="uploader(hero.img_mobile)">
								<img :src="uploader(hero.img_mobile)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero.title }}</h2>
								</div>
							</div>
						</div>
		            </div>
		        </div>
		    </div>
		</section>
		<section id="content" style="background-color: #000; overflow: visible">
			<div class="content-wrap pt-0 pb-0">
				<div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
					<div class="container clearfix">
						<div class="row justify-content-center ">
							<div class="col-lg-6 col-md-7">
								<div class="wrap_join">
									<h2 class="text-left">{{web.lbl_become_affiliate}}</h2>
									<p class="text-left">{{web.lbl_desc_become_affiliate}} </p>
									<VForm @resp="onSubmit">
										<div class="form-row">
											<div class="col-md-12">
												<label>{{web.lbl_name_outlet}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<input type="text" onkeydown="return fullNameKey(event)" v-model="input.outlet_name" v-bind="validation('outlet_name')" class="frm_custom_sign" :placeholder="web.lbl_name_outlet" />
													<LabelError name="outlet_name"></LabelError>
												</div>
											</div>
											<div class="col-md-12">
												<label>{{web.lbl_no_handphone}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<input type="tel" name="hp_no" onkeydown="return phoneKey(event)" v-model="input.hp_no" v-bind="validation('hp_no')" class="frm_custom_sign" placeholder="No Handphone" />
													<LabelError name="hp_no"></LabelError>
												</div>
											</div>
											<div class="col-md-12">
												<label>{{web.lbl_website}}</label>
												<div class="form-group">
													<input type="url" name="url" v-model="input.url" v-bind="validation('url')" class="frm_custom_sign" placeholder="https://mywebsite.com" />
													<LabelError name="url"></LabelError>
												</div>
											</div>
											<div class="col-md-12 mb-3">
												<label>{{web.lbl_country}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group select_custom_dark">
													<select2 :url="'/select-country-form'" :object="['id','value']" v-model="input.country" name="country" class="valid">
														<option value="">{{web.lbl_select_country}}</option>
													</select2>
													<LabelError name="country"></LabelError>
												</div>
											</div>
											<div class="col-md-8">
												<label>{{web.lbl_city}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<input type="text" v-model="input.city" name="city" v-bind="validation('city')" class="frm_custom_sign" :placeholder="web.lbl_city" />
													<LabelError name="city"></LabelError>
												</div>
											</div>
											<div class="col-md-4">
												<label>{{web.lbl_zip_code}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<input type="text" onkeydown="return numberKey(event)" v-model="input.zip_code" name="zip_code" v-bind="validation('zip_code')" class="frm_custom_sign" :placeholder="web.lbl_zip_code" />
													<LabelError name="zip_code"></LabelError>
												</div>
											</div>
											<div class="col-md-12">
												<label>{{web.lbl_street_address}} <small class="mandatory_txt">*</small>
												</label>
												<div class="form-group">
													<textarea class="frm_custom_sign h_auto" rows="4" name="address" v-model="input.address" v-bind="validation('address')"  :placeholder="web.lbl_street_address"></textarea>
													<LabelError name="address"></LabelError>
												</div>
											</div>
											<div class="col-md-12 text-center">
												<p><button type="submit" class="btn_line w_100 btn-loading">{{web.btn_submit}} <i class="icon-line-arrow-right"></i></button></p>
											</div>
										</div>
									</VForm>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>
		</section>
	</div>
</template>